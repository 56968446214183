export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Software House',
    headline: 'Revolutionize Your Business with Our Innovative Software Solutions',
    description:
      'We are a software company that specializes in providing cutting-edge solutions to businesses of all sizes.',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup, Effortless Success',
    headline: 'Extremely quick onboarding process',
    description:
      "Once you with us, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Building Your Vision',
    headline:
      'Craft an Application That Sets You Apart',
    description:
      "At Scripter Technology, we take pride in helping businesses transform their ideas into exceptional mobile experiences. Our dedicated team of experts is committed to delivering innovative solutions that not only meet but exceed your expectations.",
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/profile.jpg'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    start: 'true'
  };